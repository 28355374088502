<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        :show-select="false"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Müşteriler"
            icon="mdi-office-building-cog-outline"
            :add-route="{ name: 'admin.crm.customers.create' }"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          ></rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'admin.crm.customers.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ value }">
          <rs-table-cell-email :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.payment_warning_level="{ value }">
          <template v-if="value === 0">
            <em>Yok</em>
          </template>
          <template v-else-if="value === 1"> Birinci uyarı </template>
          <template v-else-if="value === 2">
            <strong>İkinci uyarı</strong>
          </template>
          <template v-else-if="value === 3">
            <strong class="text-danger">Kapatıldı</strong>
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.customer_group_name="{ item, value }">
          <router-link
            :to="{
              name: 'admin.crm.customer-groups.edit',
              params: { id: item.customer_group_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.product_name="{ item, value }">
          <router-link
            v-if="item.product_id"
            :to="{
              name: 'admin.products.edit',
              params: { id: item.product_id },
            }"
          >
            {{ value }}
          </router-link>
          <em v-else>Yok</em>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.product_starts_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.product_ends_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.cluster_count="{ value }">
          <rs-table-cell-number :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.house_count="{ value }">
          <rs-table-cell-number :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.active_product_count="{ value }">
          <strong v-if="value === 0" class="text-danger">0</strong>
          <template v-else>{{ value }}</template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import { mapGetters } from "vuex";
import { paymentWarningLevels } from "../../../../core/data/options";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["roleList"]),
  },
  data() {
    return {
      options: {
        sortBy: ["name"],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Adı",
          value: "name",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Unvan",
          value: "title",
          searchable: "text",
          width: "300px",
        },
        {
          text: "E-posta",
          value: "email",
          searchable: "text",
        },
        {
          text: "VKN/TCKN",
          value: "tax_number",
          searchable: "text",
        },
        {
          text: "TYA",
          value: "cluster_count",
          searchable: "number",
          notPrice: true,
          align: "end",
        },
        {
          text: "BB",
          value: "house_count",
          searchable: "number",
          notPrice: true,
          align: "end",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: "Grup",
          value: "customer_group_name",
          searchable: "text",
        },
        {
          text: "Ürün",
          value: "product_name",
          searchable: "text",
        },
        {
          text: "Ürün Başlangıç",
          value: "product_starts_on",
          searchable: "date",
        },
        {
          text: "Ürün Bitiş",
          value: "product_ends_on",
          searchable: "date",
        },
        {
          text: "Aktif Ürün Sayısı",
          value: "active_product_count",
          searchable: "number",
          align: "end",
        },
        {
          text: "Yetkili Kişi",
          value: "authorized_user_name",
          searchable: "text",
        },
        {
          text: "Adres",
          value: "address",
          searchable: "text",
          width: "400px",
        },
        {
          text: "Ödeme Uyarı Seviyesi",
          value: "payment_warning_level",
          searchable: "select",
          options: () => paymentWarningLevels,
        },
        {
          text: "Oluşturma",
          value: "created_at",
          searchable: "date",
          width: "180px",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => "api-super-admin/customers",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("api-super-admin/customers", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
  },
};
</script>
